import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import HomePage from "./pages/homepage";
import Login from "./pages/login";
import Bet from './pages/betscreen';
import UserResults from './pages/userresults';
import ResultView from './pages/resultview';
import AdmConfig from './pages/config';
import RoundRanking from './pages/roundranking';
import GeralRanking from './pages/anualranking';
import BuyTickets from './pages/buytickets';
import CriarConta from './pages/newaccount';


const PrivateRoute = ({ component: Component }) => {
    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.auth;
    const isAuthenticated = () => vrf === 'auth';

    return isAuthenticated() ? <Component /> : <Navigate to="/" replace />;
};

export default () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/criarconta" element={<CriarConta />} />
                <Route path="/cdh5co27nxqj" element={<AdmConfig />} />
                <Route path="/home" element={<PrivateRoute component={HomePage} />} />
                <Route path="/bet" element={<PrivateRoute component={Bet} />} />
                <Route path="/results" element={<PrivateRoute component={UserResults} />} />
                <Route path="/resultsview" element={<PrivateRoute component={ResultView} />} />
                <Route path="/roundranking" element={<PrivateRoute component={RoundRanking} />} />
                <Route path="/yearranking" element={<PrivateRoute component={GeralRanking} />} />
                <Route path="/buytickets" element={<PrivateRoute component={BuyTickets} />} />
            </Routes>
        </BrowserRouter>
    );
}