import React, { useEffect, useState } from "react";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import i1 from '../img/athleticopr.svg';
import i2 from '../img/atleticogo.svg';
import i3 from '../img/atleticomg.svg';
import i4 from '../img/bahia.svg';
import i5 from '../img/botafogo.svg';
import i6 from '../img/bragantino.svg';
import i7 from '../img/corinthians.svg';
import i8 from '../img/criciuma.svg';
import i9 from '../img/cruzeiro.svg';
import i10 from '../img/cuiaba.svg';
import i11 from '../img/flamengo.svg';
import i12 from '../img/fluminense.svg';
import i13 from '../img/fortaleza.svg';
import i14 from '../img/gremio.svg';
import i15 from '../img/internacional.svg';
import i16 from '../img/juventude.svg';
import i17 from '../img/palmeiras.svg';
import i18 from '../img/saopaulo.svg';
import i19 from '../img/vasco.svg';
import i20 from '../img/vitoria.svg';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const ResultView = () => {

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const location = useLocation();
    const { id } = location.state || {vrf};
    const [loading, setLoading] = useState(false); // <<<<<======-------- ALTERAR PRA TRUE
    const navigate = useNavigate();
    const [game1Option, setGame1Option] = useState('');
    const [game2Option, setGame2Option] = useState('');
    const [game3Option, setGame3Option] = useState('');
    const [game4Option, setGame4Option] = useState('');
    const [game5Option, setGame5Option] = useState('');
    const [game6Option, setGame6Option] = useState('');
    const [game7Option, setGame7Option] = useState('');
    const [game8Option, setGame8Option] = useState('');
    const [game9Option, setGame9Option] = useState('');
    const [game10Option, setGame10Option] = useState('');
    const [gameData, setGameData] = useState(new Array(10).fill(null));
    const [dataResults, setDataResults] = useState('');
    const [userBetResults, setUserBetResults] = useState('');
    const [wlVrf1, setWlVrf1] = useState('m-noplayed');
    const [wlVrf2, setWlVrf2] = useState('m-noplayed');
    const [wlVrf3, setWlVrf3] = useState('m-noplayed');
    const [wlVrf4, setWlVrf4] = useState('m-noplayed');
    const [wlVrf5, setWlVrf5] = useState('m-noplayed');
    const [wlVrf6, setWlVrf6] = useState('m-noplayed');
    const [wlVrf7, setWlVrf7] = useState('m-noplayed');
    const [wlVrf8, setWlVrf8] = useState('m-noplayed');
    const [wlVrf9, setWlVrf9] = useState('m-noplayed');
    const [wlVrf10, setWlVrf10] = useState('m-noplayed');
    const [betUsrVr, setBetUsrVr] = useState('');
    const [roundNumber, setRoundNumber] = useState(0);

    useEffect(() => {
        const vrfBetUser = () => {
            if (userBetResults !== '' && userBetResults !== null && userBetResults !== undefined) {
                setGame1Option(userBetResults[0]);
                setGame2Option(userBetResults[1]);
                setGame3Option(userBetResults[2]);
                setGame4Option(userBetResults[3]);
                setGame5Option(userBetResults[4]);
                setGame6Option(userBetResults[5]);
                setGame7Option(userBetResults[6]);
                setGame8Option(userBetResults[7]);
                setGame9Option(userBetResults[8]);
                setGame10Option(userBetResults[9]);
            }

        }

        if (loading && userBetResults) {
            vrfBetUser();
        }

    }, [loading, userBetResults]);

    useEffect(() => {
        const vrfWinRoundRate = () => {

            if (dataResults[0] === 0) {
                setWlVrf1('m-noplayed')
            } else if (dataResults[0] === userBetResults[0]) {
                setWlVrf1('m-win')
            } else {
                setWlVrf1('m-lose')
            }

            if (dataResults[1] === 0) {
                setWlVrf2('m-noplayed')
            } else if (dataResults[1] === userBetResults[1]) {
                setWlVrf2('m-win')
            } else {
                setWlVrf2('m-lose')
            }

            if (dataResults[2] === 0) {
                setWlVrf3('m-noplayed')
            } else if (dataResults[2] === userBetResults[2]) {
                setWlVrf3('m-win')
            } else {
                setWlVrf3('m-lose')
            }

            if (dataResults[3] === 0) {
                setWlVrf4('m-noplayed')
            } else if (dataResults[3] === userBetResults[3]) {
                setWlVrf4('m-win')
            } else {
                setWlVrf4('m-lose')
            }

            if (dataResults[4] === 0) {
                setWlVrf5('m-noplayed')
            } else if (dataResults[4] === userBetResults[4]) {
                setWlVrf5('m-win')
            } else {
                setWlVrf5('m-lose')
            }

            if (dataResults[5] === 0) {
                setWlVrf6('m-noplayed')
            } else if (dataResults[5] === userBetResults[5]) {
                setWlVrf6('m-win')
            } else {
                setWlVrf6('m-lose')
            }

            if (dataResults[6] === 0) {
                setWlVrf7('m-noplayed')
            } else if (dataResults[6] === userBetResults[6]) {
                setWlVrf7('m-win')
            } else {
                setWlVrf7('m-lose')
            }

            if (dataResults[7] === 0) {
                setWlVrf8('m-noplayed')
            } else if (dataResults[7] === userBetResults[7]) {
                setWlVrf8('m-win')
            } else {
                setWlVrf8('m-lose')
            }

            if (dataResults[8] === 0) {
                setWlVrf9('m-noplayed')
            } else if (dataResults[8] === userBetResults[8]) {
                setWlVrf9('m-win')
            } else {
                setWlVrf9('m-lose')
            }

            if (dataResults[9] === 0) {
                setWlVrf10('m-noplayed')
            } else if (dataResults[9] === userBetResults[9]) {
                setWlVrf10('m-win')
            } else {
                setWlVrf10('m-lose')
            }
        }

        if (loading && dataResults && userBetResults) {
            vrfWinRoundRate();
        }

    }, [loading, dataResults, userBetResults]);

    const backToHome = () => {
        navigate('/roundranking');
    }

    const teams = {
        1: "Athletico-PR",
        2: "Atlético-GO",
        3: "Atlético-MG",
        4: "Bahia",
        5: "Botafogo",
        6: "Bragantino",
        7: "Corinthians",
        8: "Criciúma",
        9: "Cruzeiro",
        10: "Cuiabá",
        11: "Flamengo",
        12: "Fluminense",
        13: "Fortaleza",
        14: "Grêmio",
        15: "Internacional",
        16: "Juventude",
        17: "Palmeiras",
        18: "São Paulo",
        19: "Vasco",
        20: "Vitória",
        21: "Empate"
    };

    const getTeamImage = (teamNumber) => {
        switch (teamNumber) {
            case 1: return i1;
            case 2: return i2;
            case 3: return i3;
            case 4: return i4;
            case 5: return i5;
            case 6: return i6;
            case 7: return i7;
            case 8: return i8;
            case 9: return i9;
            case 10: return i10;
            case 11: return i11;
            case 12: return i12;
            case 13: return i13;
            case 14: return i14;
            case 15: return i15;
            case 16: return i16;
            case 17: return i17;
            case 18: return i18;
            case 19: return i19;
            case 20: return i20;
            default: return '';
        }
    };

    const userNotBet = () => {
        if (betUsrVr === 'nobet') {
            return (
                <div className="bet-not-founded">
                    <div>
                        <p>Você não palpitou esta rodada!</p>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const fetchData = async () => {
        const db = getFirestore(app);

        const gamesRefs = Array.from({ length: 10 }, (_, index) =>
            doc(db, 'partidas', `Jogo ${index + 1}`)
        );

        try {
            const snapshots = await Promise.all(gamesRefs.map((gameRef) => getDoc(gameRef)));

            const gamesInfo = snapshots.map((docSnapshot) => {
                if (docSnapshot.exists()) {
                    return docSnapshot.data();
                } else {
                    console.log('Erro!');
                    return null;
                }
            });

            setGameData(gamesInfo);
        } catch (error) {
            console.error('Erro!');
        } finally {
            setLoading(true);
        }
    };

    const recuperarNumeroRodada = async () => {
        const db = getFirestore(app);
        const numeroRodadaDoc = doc(db, 'round_number', 'round');

        try {
            const numeroRodadaDocSnapshot = await getDoc(numeroRodadaDoc)
            if (numeroRodadaDocSnapshot.exists()) {
                const nRodada = numeroRodadaDocSnapshot.data();
                setRoundNumber(nRodada.number)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Err!');
        }
    }


    useEffect(() => {
        fetchData();
        recuperarNumeroRodada();
    }, []);

    const fetchData2 = async () => {
        const db = getFirestore(app);
        const roundResultDocument = doc(db, 'round_result', 'result');
        const roundUserBetDocument = doc(db, 'palpites', id);

        try {
            const [roundResultDocSnapshot, roundUserBetDocumentDocSnapshot] = await Promise.all([
                getDoc(roundResultDocument),
                getDoc(roundUserBetDocument),
            ]);

            if (roundResultDocSnapshot.exists()) {
                const dataBetResults = roundResultDocSnapshot.data();
                setDataResults(dataBetResults.games);
            } else {
                console.log('Erro!');
            }

            if (roundUserBetDocumentDocSnapshot.exists()) {
                const userDataBetResults = roundUserBetDocumentDocSnapshot.data();
                setUserBetResults(userDataBetResults.palpites);
            } else {
                console.log('Erro!');
                setBetUsrVr('nobet');
            }

        } catch (error) {
            console.error('Error!');
        }
    };

    useEffect(() => {
        fetchData2();
    }, []);

    if (loading) {

        return (
            <section>

                <header className="navbar-bet">
                    <h3>{`Rodada ${roundNumber}`}</h3>
                    <div className="back-ico">
                        <IoChevronBackCircleSharp onClick={backToHome} size={30} color="#FFF" />
                    </div>
                </header>

                <div className="spacing"></div>
                <div className="infoOptions">
                    <span>Casa</span>
                    <span>Empate</span>
                    <span>Fora</span>
                </div>

                {userNotBet()}

                <div className="partidas">
                    <div className={wlVrf1 ? wlVrf1 : 'm-noplayed'}>
                        <div><h3>Jogo 1</h3></div>
                        <div><h4>{gameData[0]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[0]?.casa)} />
                                <p className="teamName">{teams[gameData[0]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game1"
                                    value="1"
                                    checked={game1Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game1"
                                    value="2"
                                    checked={game1Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game1"
                                    value="3"
                                    checked={game1Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[0]?.visitante)} />
                                <p className="teamName">{teams[gameData[0]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf2 ? wlVrf2 : 'm-noplayed'}>
                        <div><h3>Jogo 2</h3></div>
                        <div><h4>{gameData[1]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[1]?.casa)} />
                                <p className="teamName">{teams[gameData[1]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game2"
                                    value="1"
                                    checked={game2Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game2"
                                    value="2"
                                    checked={game2Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game2"
                                    value="3"
                                    checked={game2Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[1]?.visitante)} />
                                <p className="teamName">{teams[gameData[1]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf3 ? wlVrf3 : 'm-noplayed'}>
                        <div><h3>Jogo 3</h3></div>
                        <div><h4>{gameData[2]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[2]?.casa)} />
                                <p className="teamName">{teams[gameData[2]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game3"
                                    value="1"
                                    checked={game3Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game3"
                                    value="2"
                                    checked={game3Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game3"
                                    value="3"
                                    checked={game3Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[2]?.visitante)} />
                                <p className="teamName">{teams[gameData[2]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf4 ? wlVrf4 : 'm-noplayed'}>
                        <div><h3>Jogo 4</h3></div>
                        <div><h4>{gameData[3]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[3]?.casa)} />
                                <p className="teamName">{teams[gameData[3]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game4"
                                    value="1"
                                    checked={game4Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game4"
                                    value="2"
                                    checked={game4Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game4"
                                    value="3"
                                    checked={game4Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[3]?.visitante)} />
                                <p className="teamName">{teams[gameData[3]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf5 ? wlVrf5 : 'm-noplayed'}>
                        <div><h3>Jogo 5</h3></div>
                        <div><h4>{gameData[4]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[4]?.casa)} />
                                <p className="teamName">{teams[gameData[4]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game5"
                                    value="1"
                                    checked={game5Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game5"
                                    value="2"
                                    checked={game5Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game5"
                                    value="3"
                                    checked={game5Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[4]?.visitante)} />
                                <p className="teamName">{teams[gameData[4]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf6 ? wlVrf6 : 'm-noplayed'}>
                        <div><h3>Jogo 6</h3></div>
                        <div><h4>{gameData[5]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[5]?.casa)} />
                                <p className="teamName">{teams[gameData[5]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game6"
                                    value="1"
                                    checked={game6Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game6"
                                    value="2"
                                    checked={game6Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game6"
                                    value="3"
                                    checked={game6Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[5]?.visitante)} />
                                <p className="teamName">{teams[gameData[5]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf7 ? wlVrf7 : 'm-noplayed'}>
                        <div><h3>Jogo 7</h3></div>
                        <div><h4>{gameData[6]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[6]?.casa)} />
                                <p className="teamName">{teams[gameData[6]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game7"
                                    value="1"
                                    checked={game7Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game7"
                                    value="2"
                                    checked={game7Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game7"
                                    value="3"
                                    checked={game7Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[6]?.visitante)} />
                                <p className="teamName">{teams[gameData[6]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf8 ? wlVrf8 : 'm-noplayed'}>
                        <div><h3>Jogo 8</h3></div>
                        <div><h4>{gameData[7]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[7]?.casa)} />
                                <p className="teamName">{teams[gameData[7]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game8"
                                    value="1"
                                    checked={game8Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game8"
                                    value="2"
                                    checked={game8Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game8"
                                    value="3"
                                    checked={game8Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[7]?.visitante)} />
                                <p className="teamName">{teams[gameData[7]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf9 ? wlVrf9 : 'm-noplayed'}>
                        <div><h3>Jogo 9</h3></div>
                        <div><h4>{gameData[8]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[8]?.casa)} />
                                <p className="teamName">{teams[gameData[8]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game9"
                                    value="1"
                                    checked={game9Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game9"
                                    value="2"
                                    checked={game9Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game9"
                                    value="3"
                                    checked={game9Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[8]?.visitante)} />
                                <p className="teamName">{teams[gameData[8]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className={wlVrf10 ? wlVrf10 : 'm-noplayed'}>
                        <div><h3>Jogo 10</h3></div>
                        <div><h4>{gameData[9]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[9]?.casa)} />
                                <p className="teamName">{teams[gameData[9]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game10"
                                    value="1"
                                    checked={game10Option === 1}
                                    disabled
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game10"
                                    value="2"
                                    checked={game10Option === 2}
                                    disabled
                                />
                                <input
                                    type="radio"
                                    name="game10"
                                    value="3"
                                    checked={game10Option === 3}
                                    disabled
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[9]?.visitante)} />
                                <p className="teamName">{teams[gameData[9]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="spacing-btm">
                        <span></span>
                    </div>

                </div>

            </section >
        )
    }
}

export default ResultView;