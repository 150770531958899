import React, { useEffect, useState } from "react";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import anmUserIco from '../img/anonymous_user.png';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const GeralRanking = () => {
    const [loading, setLoading] = useState(false);
    const [geralRankingData, setGeralRankingData] = useState([]);
    const [userNames, setUserNames] = useState({});
    const [userPosition, setUserPosition] = useState(null);
    const navigate = useNavigate();

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const fetchUserNames = async () => {
        const userNamesData = {};

        await Promise.all(geralRankingData.map(async (userData) => {
            const db = getFirestore(app);
            const userDocRef = doc(db, 'user_data', userData.id);

            try {
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userDataFromUsersCollection = userDocSnapshot.data();
                    userNamesData[userData.id] = {
                        name: userDataFromUsersCollection.name,
                        imageUrl: userDataFromUsersCollection.imageUrl,
                    };
                }
            } catch (error) {
                console.error('Err!');
            }
        }));

        // Atualizar o estado com os nomes dos usuários
        setUserNames(userNamesData);
    };

    useEffect(() => {
        fetchUserNames();
    }, [geralRankingData]);

    const fetchGeralRankingData = async () => {
        const db = getFirestore(app);
        const geralRankingCollection = collection(db, 'geral_ranking');

        try {
            const snapshot = await getDocs(geralRankingCollection);
            const ordenaData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            ordenaData.sort((a, b) => b.points - a.points);

            setGeralRankingData(ordenaData);

            const userIndex = ordenaData.findIndex(userData => userData.id === vrf);
            setUserPosition(userIndex !== -1 ? userIndex + 1 : null);
        } catch (error) {
            console.error('Err!');
        }
    };

    useEffect(() => {
        fetchGeralRankingData();
        setLoading(true);
    }, []);

    const backToHome = () => {
        navigate('/home');
    }

    const renderRanking = () => {

        return geralRankingData.map((userData, index) => (
            <div key={userData.id} className="container-candidate">
                <div className="ranking-usr-pos">
                    <p>{index + 1}º</p>
                </div>
                <div className="ranking-usr-img">
                    <img src={userNames[userData.id]?.imageUrl || anmUserIco} />
                </div>
                <div className="ranking-usr-name">
                    <h2>{userNames[userData.id]?.name}</h2>
                </div>
                <div className="ranking-usr-pont">
                    <h1>{userData.points}</h1>
                </div>
            </div>
        ));
    }

    if (loading) {

        return (
            <section>

                <header className="navbar-bet">
                    <h3>Ranking da Rodada</h3>
                    <div className="back-ico">
                        <IoChevronBackCircleSharp onClick={backToHome} size={30} color="#FFF" />
                    </div>


                </header>
                <div className="scrolltest">

                    <div className="ranking-container">

                        <div className="user-position">
                            <p>Sua posição: <strong>{userPosition === null ? 'Não Definido' : `${userPosition}º`}</strong></p>
                        </div>

                        {renderRanking()}

                    </div>
                </div>


            </section>
        )
    }
}

export default GeralRanking;