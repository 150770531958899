import React, { useEffect, useState } from 'react';
import Navbar from "../components/navbar";
import anmUserIco from '../img/anonymous_user.png';
import { IoWallet, IoTicketOutline } from "react-icons/io5";
import { FaRankingStar } from "react-icons/fa6";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { TbMoneybag } from "react-icons/tb";
import { IoIosFootball } from "react-icons/io";
import { GiSoccerKick } from "react-icons/gi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const HomePage = () => {
    const [ticketCount, setTicketCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataResults, setDataResults] = useState('');
    const [userBetResults, setUserBetResults] = useState('');
    const [wlVrf1, setWlVrf1] = useState('noplayed');
    const [wlVrf2, setWlVrf2] = useState('noplayed');
    const [wlVrf3, setWlVrf3] = useState('noplayed');
    const [wlVrf4, setWlVrf4] = useState('noplayed');
    const [wlVrf5, setWlVrf5] = useState('noplayed');
    const [wlVrf6, setWlVrf6] = useState('noplayed');
    const [wlVrf7, setWlVrf7] = useState('noplayed');
    const [wlVrf8, setWlVrf8] = useState('noplayed');
    const [wlVrf9, setWlVrf9] = useState('noplayed');
    const [wlVrf10, setWlVrf10] = useState('noplayed');
    const [userRoundPosition, setUserRoundPosition] = useState(null);
    const [userGeralPosition, setUserGeralPosition] = useState(null);
    const [lockedRound, setLockedRound] = useState('');
    const [roundPrize, setRoundPrize] = useState(0);
    const [geralPrize, setGeralPrize] = useState(0);
    const [roundNumber, setRoundNumber] = useState(0);
    const [imgUser, setImgUser] = useState(null);
    const navigate = useNavigate();

    const toTheRoundRanking = () => {
        navigate('/roundranking');
    }

    const toTheGeralRanking = () => {
        navigate('/yearranking');
    }

    const toTheUserResults = () => {
        navigate('/results');
    }

    const toTheBuyTickets = () => {
        logEvent(analytics, 'button_click', {
            button_name: 'buy_tickets_button',
          });
        navigate('/buytickets');
    }

    useEffect(() => {
        const vrfWinRoundRate = () => {
            if (dataResults[0] === 0) {
                setWlVrf1('noplayed')
            } else if (dataResults[0] === userBetResults[0]) {
                setWlVrf1('win')
            } else {
                setWlVrf1('lose')
            }

            if (dataResults[1] === 0) {
                setWlVrf2('noplayed')
            } else if (dataResults[1] === userBetResults[1]) {
                setWlVrf2('win')
            } else {
                setWlVrf2('lose')
            }

            if (dataResults[2] === 0) {
                setWlVrf3('noplayed')
            } else if (dataResults[2] === userBetResults[2]) {
                setWlVrf3('win')
            } else {
                setWlVrf3('lose')
            }

            if (dataResults[3] === 0) {
                setWlVrf4('noplayed')
            } else if (dataResults[3] === userBetResults[3]) {
                setWlVrf4('win')
            } else {
                setWlVrf4('lose')
            }

            if (dataResults[4] === 0) {
                setWlVrf5('noplayed')
            } else if (dataResults[4] === userBetResults[4]) {
                setWlVrf5('win')
            } else {
                setWlVrf5('lose')
            }

            if (dataResults[5] === 0) {
                setWlVrf6('noplayed')
            } else if (dataResults[5] === userBetResults[5]) {
                setWlVrf6('win')
            } else {
                setWlVrf6('lose')
            }

            if (dataResults[6] === 0) {
                setWlVrf7('noplayed')
            } else if (dataResults[6] === userBetResults[6]) {
                setWlVrf7('win')
            } else {
                setWlVrf7('lose')
            }

            if (dataResults[7] === 0) {
                setWlVrf8('noplayed')
            } else if (dataResults[7] === userBetResults[7]) {
                setWlVrf8('win')
            } else {
                setWlVrf8('lose')
            }

            if (dataResults[8] === 0) {
                setWlVrf9('noplayed')
            } else if (dataResults[8] === userBetResults[8]) {
                setWlVrf9('win')
            } else {
                setWlVrf9('lose')
            }

            if (dataResults[9] === 0) {
                setWlVrf10('noplayed')
            } else if (dataResults[9] === userBetResults[9]) {
                setWlVrf10('win')
            } else {
                setWlVrf10('lose')
            }
        }

        if (loading && dataResults && userBetResults) {
            vrfWinRoundRate();
        }

    }, [loading, dataResults, userBetResults]);

    const betError = () => toast.error('Tickets insuficientes. Compre mais!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold', // Defina o tamanho da fonte desejado
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const makeBet = () => {
        if (ticketCount < 1) {
            betError();
        } else {
            navigate('/bet');
        }
    }

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const fetchRoundRankingData = async () => {
        const db = getFirestore(app);
        const roundRankingCollection = collection(db, 'round_ranking');

        try {
            const snapshot = await getDocs(roundRankingCollection);
            const ordenaData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            ordenaData.sort((a, b) => b.points - a.points);

            const userIndex = ordenaData.findIndex(userData => userData.id === vrf);
            setUserRoundPosition(userIndex !== -1 ? userIndex + 1 : null);
        } catch (error) {
            console.error('Err!');
        }
    };

    const fetchGeralRankingData = async () => {
        const db = getFirestore(app);
        const GeralRankingCollection = collection(db, 'geral_ranking');

        try {
            const snapshot = await getDocs(GeralRankingCollection);
            const ordenaData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            ordenaData.sort((a, b) => b.points - a.points);

            const userIndex = ordenaData.findIndex(userData => userData.id === vrf);
            setUserGeralPosition(userIndex !== -1 ? userIndex + 1 : null);
        } catch (error) {
            console.error('Err!');
        }
    };

    const recuperarStatusRodada = async () => {
        const db = getFirestore(app);
        const statusRound = doc(db, 'locked_round', 'verify');

        try {
            const statusRoundSnapshsot = await getDoc(statusRound)
            if (statusRoundSnapshsot.exists()) {
                const rStatus = statusRoundSnapshsot.data();
                setLockedRound(rStatus.status)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Errr!');
        }
    };

    const recuperarPremioRodada = async () => {
        const db = getFirestore(app);
        const prizeRoundDoc = doc(db, 'round_prize', 'prize');

        try {
            const prizeRoundDocSnapshot = await getDoc(prizeRoundDoc)
            if (prizeRoundDocSnapshot.exists()) {
                const rPrize = prizeRoundDocSnapshot.data();
                setRoundPrize(rPrize.value)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Errr!');
        }
    }

    const recuperarPremioGeral = async () => {
        const db = getFirestore(app);
        const prizeGeralDoc = doc(db, 'geral_prize', 'prize');

        try {
            const prizeGeralDocSnapshot = await getDoc(prizeGeralDoc)
            if (prizeGeralDocSnapshot.exists()) {
                const gPrize = prizeGeralDocSnapshot.data();
                setGeralPrize(gPrize.value)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Errr!');
        }
    }

    const recuperarNumeroRodada = async () => {
        const db = getFirestore(app);
        const numeroRodadaDoc = doc(db, 'round_number', 'round');

        try {
            const numeroRodadaDocSnapshot = await getDoc(numeroRodadaDoc)
            if (numeroRodadaDocSnapshot.exists()) {
                const nRodada = numeroRodadaDocSnapshot.data();
                setRoundNumber(nRodada.number)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Err!');
        }
    }

    const fetchData = async () => {
        const db = getFirestore(app);
        const userDocument = doc(db, 'user_data', vrf);
        const roundResultDocument = doc(db, 'round_result', 'result');
        const roundUserBetDocument = doc(db, 'palpites', vrf);

        try {
            const [docSnapshot, roundResultDocSnapshot, roundUserBetDocumentDocSnapshot] = await Promise.all([
                getDoc(userDocument),
                getDoc(roundResultDocument),
                getDoc(roundUserBetDocument),
            ]);
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setTicketCount(userData.tickets);
                setImgUser(userData.imageUrl);
            } else {
                console.log('Erro!');
            }

            if (roundResultDocSnapshot.exists()) {
                const dataBetResults = roundResultDocSnapshot.data();
                setDataResults(dataBetResults.games);
            } else {
                console.log('Erro!');
            }

            if (roundUserBetDocumentDocSnapshot.exists()) {
                const userDataBetResults = roundUserBetDocumentDocSnapshot.data();
                setUserBetResults(userDataBetResults.palpites);
            } else {
                console.log('Erro!');
            }

        } catch (error) {
            console.error('Error!');
        } finally {
            setLoading(true);
        }
    };

    useEffect(() => {
        fetchRoundRankingData();
        fetchGeralRankingData();
        recuperarStatusRodada();
        recuperarPremioRodada();
        recuperarPremioGeral();
        recuperarNumeroRodada();
        fetchData();
    }, []);

    const formataReal = (numero) => {
        const options = { style: 'currency', currency: 'BRL' };
        return numero.toLocaleString('pt-BR', options);
    };
    

    if (loading) {

        return (
            <section>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

                <Navbar />

                <div className="logged-user-img">
                    <img src={imgUser === null ? anmUserIco : imgUser} />
                </div>

                <div className="home-container">
                    <div className="wallet-cash">
                        <IoWallet size={30} color="yellow" />
                        <p>{ticketCount ? ticketCount : 0}</p>
                    </div>
                    <div className="buy-tickets">
                        <div onClick={toTheBuyTickets}>
                            <p>Comprar</p>
                            <IoTicketOutline size={20} color="#00aec7" />
                        </div>
                    </div>

                    <div className="mini-containers">
                        <div className="cont-1" onClick={toTheRoundRanking}>
                            <p className="label-cont">Rodada</p>
                            <h2>{userRoundPosition === null ? 'N/D' : `${userRoundPosition}º`}</h2>
                            <span><FaRankingStar size={20} /></span>
                        </div>

                        <div className="cont-2" onClick={toTheGeralRanking}>
                            <p className="label-cont">Ranking/Ano</p>
                            <h2>{userGeralPosition === null ? 'N/D' : `${userGeralPosition}º`}</h2>
                            <span><FaRankingStar size={20} /></span>
                        </div>
                    </div>

                    <div className="prizes">
                        <div className="atual-prize">
                            <p className="label-cont">Prêmio da Rodada</p>
                            <h2>{formataReal(roundPrize)}</h2>
                            <span><FaRegMoneyBillAlt size={20} /></span>
                        </div>

                        <div className="anual-prize">
                            <p className="label-cont2">Prêmio do Ano</p>
                            <h2>{formataReal(geralPrize)}</h2>
                            <span><TbMoneybag size={20} /></span>
                        </div>
                    </div>

                    <div className="round-number">
                        <h4>{`Rodada ${roundNumber}`}</h4>
                    </div>

                    <div className="mini-check-containers" onClick={toTheUserResults}>
                        <div className={wlVrf1 ? wlVrf1 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf2 ? wlVrf2 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf3 ? wlVrf3 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf4 ? wlVrf4 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf5 ? wlVrf5 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf6 ? wlVrf6 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf7 ? wlVrf7 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf8 ? wlVrf8 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf9 ? wlVrf9 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>

                        <div className={wlVrf10 ? wlVrf10 : 'noplayed'}>
                            <span className="check-ico"><IoIosFootball size={20} /></span>
                        </div>
                    </div>

                    {lockedRound === false ? (
                        <div className="mini-containers">
                            <div className="make-bet">
                                <h2 onClick={makeBet}>Palpitar</h2>
                                <GiSoccerKick size={20} />
                            </div>
                        </div>
                    ) : (

                        <div className="mini-containers">
                            <p className='alert'>Rodada Fechada!</p>
                        </div>
                    )
                    }

                </div>
            </section>
        )
    }
}

export default HomePage;