import React, { useState, useEffect, useRef } from "react";
import NavbarAlt from "../components/usernavbar";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import { IoCartOutline } from "react-icons/io5";
import { SiPix } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import PIX from "react-qrcode-pix";


const BuyTickets = () => {
    const [numberTickets, setNumberTickets] = useState(1);
    const [valueOfTickets, setValueOfTickets] = useState('');
    const [valueOfPay, setValueOfPay] = useState('');
    const [geraPix, setGeraPix] = useState(false);
    const [fullPIX, setFullPIX] = useState("");

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const pixCodeCopyRef = useRef(null);

    const copyToClipboard = () => {
        if (pixCodeCopyRef.current) {
            pixCodeCopyRef.current.select();
            document.execCommand("copy");
        }
    };

    const formataReal = (numero) => {
        const options = { style: 'currency', currency: 'BRL' };
        return numero.toLocaleString('pt-BR', options);
    };

    const aumentarQuantidade = () => {
        setNumberTickets((prevNumber) => prevNumber + 1);
    }

    const diminuirQuantidade = () => {
        if (numberTickets > 1) {
            setNumberTickets((prevNumber) => prevNumber - 1);
        }
    }

    const formatedValue = () => {
        const calc1 = numberTickets * 3;
        const calculated = formataReal(calc1);
        setValueOfTickets(calculated);
        setValueOfPay(calc1);
    }

    useEffect(() => {
        formatedValue();
    }, [numberTickets]);

    const pixGenerate = () => {
        setGeraPix(true);
    }

    return (
        <section>
            <NavbarAlt />

            {geraPix === false ? (

                <div className="container-buy-tickets">
                    <h1>Comprar Tickets</h1>

                    <div className="qnt-option">
                        <label>Selecione a Quantidade de Tickets</label>
                        <input readOnly value={numberTickets} type="number" />
                    </div>

                    <div className="buy-icons-control">
                        <div onClick={diminuirQuantidade}>
                            <CgMathMinus />
                        </div>
                        <span></span>
                        <div onClick={aumentarQuantidade}>
                            <CgMathPlus />
                        </div>
                    </div>

                    <div className="cart-vrf">
                        <div className="cart-ico">
                            <IoCartOutline />
                        </div>
                        <div className="cart-value">
                            <input readOnly value={valueOfTickets} type="text" />
                        </div>
                    </div>

                    <div className="full-width">
                        <div className="btn-buy">
                            <h2 onClick={pixGenerate}>Comprar</h2>
                            <span><SiPix /></span>
                        </div>
                    </div>

                </div>

            ) : (

                <div className="container-buy-tickets">

                    <div className="pix-info">
                        <h2>Pague por PIX QR CODE ou PIX Copia e Cola com o código abaixo.</h2>
                        <h3>O saldo leva alguns momentos para ser atualizado. Se desejar agilizar o processamento, envie o comprovante PIX pelo WhatsApp!</h3>
                        <h3 className="alert-pix">NA DESCRIÇÃO DO PIX DIGITE: {vrf}</h3>
                    </div>

                    <div className="pix-qr-code">
                        <PIX
                            pixkey="3bbc1266-d451-4999-af18-a6fed31df490"
                            merchant="Silas Pires Feitosa"
                            city="Vitoria da Conq"
                            cep="45000000"
                            code={vrf}
                            amount={valueOfPay}
                            onLoad={setFullPIX}
                            resize={250}
                            variant="fluid"
                            padding={30}
                            color="#357"
                            bgRounded
                        />
                    </div>

                    <div className="pix-code-copy" onClick={copyToClipboard}>
                        <p>
                            <textarea
                                ref={pixCodeCopyRef}
                                value={fullPIX}
                                readOnly
                            />
                        </p>
                    </div>

                    <div className="btn-copiar">
                        <button onClick={copyToClipboard}>COPIAR</button>
                    </div>

                    <div className="btn-send-wpp">
                        <div>
                            <a href="https://api.whatsapp.com/send?phone=5577988190015&text=%5BPalpiteiro%20App%5D%0A%0AOl%C3%A1,%20quero%20enviar%20meu%20comprovante%20PIX!" target="_blank">
                                <p>Enviar Comprovante</p>
                                <span><IoLogoWhatsapp /></span>
                            </a>
                        </div>
                    </div>

                </div>

            )
            }


        </section>
    )
}

export default BuyTickets;