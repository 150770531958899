import React, { useEffect, useState } from "react";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import anmUserIco from '../img/anonymous_user.png';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const RoundRanking = () => {
    const [loading, setLoading] = useState(false);
    const [lockedRound, setLockedRound] = useState('');
    const [roundRankingData, setRoundRankingData] = useState([]);
    const [userNames, setUserNames] = useState({});
    const [userPosition, setUserPosition] = useState(null);
    const navigate = useNavigate();

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const fetchUserNames = async () => {
        const userNamesData = {};

        await Promise.all(roundRankingData.map(async (userData) => {
            const db = getFirestore(app);
            const userDocRef = doc(db, 'user_data', userData.id);

            try {
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userDataFromUsersCollection = userDocSnapshot.data();
                    userNamesData[userData.id] = {
                        name: userDataFromUsersCollection.name,
                        imageUrl: userDataFromUsersCollection.imageUrl,
                    };
                }
            } catch (error) {
                console.error('Err!');
            }
        }));

        setUserNames(userNamesData);
    };

    const recuperarStatusRodada = async () => {
        const db = getFirestore(app);
        const statusRound = doc(db, 'locked_round', 'verify');

        try {
            const statusRoundSnapshsot = await getDoc(statusRound)
            if (statusRoundSnapshsot.exists()) {
                const rStatus = statusRoundSnapshsot.data();
                setLockedRound(rStatus.status)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Errr!');
        }
    };

    useEffect(() => {
        recuperarStatusRodada();
        fetchUserNames();
    }, [roundRankingData]);

    const betWait = () => toast.warn('Aguarde o fechamento da rodada!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold',
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const fetchRoundRankingData = async () => {
        const db = getFirestore(app);
        const roundRankingCollection = collection(db, 'round_ranking');

        try {
            const snapshot = await getDocs(roundRankingCollection);
            const ordenaData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            ordenaData.sort((a, b) => b.points - a.points);

            setRoundRankingData(ordenaData);

            const userIndex = ordenaData.findIndex(userData => userData.id === vrf);
            setUserPosition(userIndex !== -1 ? userIndex + 1 : null);
        } catch (error) {
            console.error('Err!');
        }
    };

    useEffect(() => {
        fetchRoundRankingData();
        setLoading(true);
    }, []);

    const backToHome = () => {
        navigate('/home');
    }

    const renderRanking = () => {
        const handleClickBets = (id) => {
            if (lockedRound === true) {
                navigate('/resultsview', { state: { id } });
            } else {
                betWait();
            }
        };
    
        return roundRankingData.map((userData, index) => {
            const extractBaseId = (id) => {
                const regex = /^p\d+a/;
                const match = id.match(regex);
                return match ? match[0] : id;
            };
    
            const baseId = extractBaseId(userData.id);
    
            return (
                <div key={userData.id} className="container-candidate" onClick={() => handleClickBets(userData.id)}>
                    <div className="ranking-usr-pos">
                        <p>{index + 1}º</p>
                    </div>
                    <div className="ranking-usr-img">
                        <img src={userNames[baseId]?.imageUrl || anmUserIco} />
                    </div>
                    <div className="ranking-usr-name">
                        <h2>{userNames[baseId]?.name}</h2>
                    </div>
                    <div className="ranking-usr-pont">
                        <h1>{userData.points}</h1>
                    </div>
                </div>
            );
        });
    }

    if (loading) {

        return (
            <section>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

                <header className="navbar-bet">
                    <h3>Ranking da Rodada</h3>
                    <div className="back-ico">
                        <IoChevronBackCircleSharp onClick={backToHome} size={30} color="#FFF" />
                    </div>


                </header>
                <div className="scrolltest">

                    <div className="ranking-container">

                        <div className="user-position">
                            <p>Sua posição: <strong>{userPosition === null ? 'Não Definido' : `${userPosition}º`}</strong></p>
                        </div>

                        {renderRanking()}

                    </div>
                </div>


            </section>
        )
    }
}

export default RoundRanking;