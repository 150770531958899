import React, { useState, useEffect } from "react";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { IoSend } from "react-icons/io5";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, doc, setDoc } from 'firebase/firestore';
import { IoLogoWhatsapp } from "react-icons/io";

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);

const CriarConta = () => {
    const navigate = useNavigate();
    const [userMessages, setUserMessages] = useState([]);
    const [botMessages, setBotMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [messageId, setMessageId] = useState(3);
    const [isBotTyping, setIsBotTyping] = useState(false);

    const backToHome = () => {
        navigate('/');
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        const userMessage = { id: messageId, text: inputText, user: 'user' };
        setUserMessages([...userMessages, userMessage]);
        setInputText('');
        setMessageId(messageId + 1);
        handleResponse(userMessage);
    }

    const handleResponse = async (userMessage) => {
        setIsBotTyping(true);
        await new Promise(resolve => setTimeout(resolve, 3000));

        const lastBotMessage = botMessages[botMessages.length - 1];

        let botResponse = [];

        if (lastBotMessage.text.toLowerCase().includes('nome')) {
            botResponse = [
                { id: messageId + 1, text: 'Agora me fala como podemos contatar você caso ganhe um prêmio. Pode ser e-mail ou telefone com DDD. 😀', user: 'bot' }
            ];
        } else if (lastBotMessage.text.toLowerCase().includes('contatar')) {
            botResponse = [
                { id: messageId + 1, text: 'Me informe também sua chave pix! Esperamos te premiar em breve. 💸', user: 'bot' }
            ];
        } else if (lastBotMessage.text.toLowerCase().includes('pix')) {
            botResponse = [
                { id: messageId + 1, text: 'Certo! Agora digite uma senha para acessar sua conta. 🔐', user: 'bot' }
            ];
        } else if (lastBotMessage.text.toLowerCase().includes('senha')) {
            botResponse = [
                { id: messageId + 1, text: 'Tudo certo! Nossa equipe está finalizando o seu cadastro e logo poderá acessar, pedimos que tente em alguns momentos. Caso demore ou queira liberar o acesso imediato entre em contato com a gente pelo WhatsApp! Aproveite e envie sua foto para o perfil. Até mais, bons palpites! 🚀', user: 'bot' }
            ];
        } else {
            botResponse = [
                { id: messageId + 1, text: 'Esta conversa foi finalizada. Entre em contato com a gente por WhatsApp!', user: 'bot' }
            ];
        }

        setBotMessages([...botMessages, ...botResponse]);
        setMessageId(messageId + 2);
        setIsBotTyping(false);
    };

    useEffect(() => {
        if (userMessages.length >= 4) {
            enviarParaOFirebase(userMessages);
        }
    }, [userMessages]);


    const allMessages = [...userMessages, ...botMessages].sort((a, b) => a.id - b.id);

    useEffect(() => {
        setBotMessages([
            { id: 1, text: 'Olá palpiteiro! Vamos criar sua nova conta?', user: 'bot' },
            { id: 2, text: 'Primeiro me diz seu nome completo.', user: 'bot' }
        ]);
    }, []);

    const enviarParaOFirebase = async (userMessages) => {
        try {
            const db = getFirestore(app);
            const usersCollectionRef = collection(db, 'users_to_create');

            const snapshot = await getDocs(usersCollectionRef);

            const numeroDocumentos = snapshot.size;

            const novoDocumentoID = numeroDocumentos + 1;

            const novoDocumentoRef = doc(usersCollectionRef, novoDocumentoID.toString());
            await setDoc(novoDocumentoRef, {
                full_name: userMessages[0].text,
                contact: userMessages[1].text,
                key_pix: userMessages[2].text,
                password: userMessages[3].text,
            });



        } catch (error) {
            console.error('Err!', error);
        }
    };

    return (
        <section>
            <header className="navbar-bet">
                <h3>Criar Conta</h3>
                <div className="back-ico">
                    <IoChevronBackCircleSharp onClick={backToHome} size={30} color="#FFF" />
                </div>
                <div className="whats-ico">
                    <a href="https://api.whatsapp.com/send?phone=5577988190015&text=%5BPalpiteiro%20App%5D%0A%0AOl%C3%A1,%20quero%20enviar%20minha%20foto%20de%20perfil!" target="_blank"><IoLogoWhatsapp size={30} color="#FFF" /></a>
                </div>
            </header>

            <div className="container-chat">
                <div className="chat-container">
                    {allMessages.map((message) => (
                        <div key={message.id} className={message.user === 'user' ? 'user-message' : 'bot-message'}>
                            {message.text}
                        </div>
                    ))}
                    {isBotTyping && <div className="bot-message">...</div>}
                </div>

                <div className="input-container">
                    <input
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Digite sua mensagem..."
                    />
                    <button onClick={handleSendMessage}><IoSend /></button>
                </div>
            </div>
        </section>
    )
}

export default CriarConta;
