import React, { useEffect, useState } from "react";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i1 from '../img/athleticopr.svg';
import i2 from '../img/atleticogo.svg';
import i3 from '../img/atleticomg.svg';
import i4 from '../img/bahia.svg';
import i5 from '../img/botafogo.svg';
import i6 from '../img/bragantino.svg';
import i7 from '../img/corinthians.svg';
import i8 from '../img/criciuma.svg';
import i9 from '../img/cruzeiro.svg';
import i10 from '../img/cuiaba.svg';
import i11 from '../img/flamengo.svg';
import i12 from '../img/fluminense.svg';
import i13 from '../img/fortaleza.svg';
import i14 from '../img/gremio.svg';
import i15 from '../img/internacional.svg';
import i16 from '../img/juventude.svg';
import i17 from '../img/palmeiras.svg';
import i18 from '../img/saopaulo.svg';
import i19 from '../img/vasco.svg';
import i20 from '../img/vitoria.svg';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Bet = () => {
    const [loading, setLoading] = useState(false); // <<<<<======-------- ALTERAR PRA TRUE
    const navigate = useNavigate();
    const [game1Option, setGame1Option] = useState(null);
    const [game2Option, setGame2Option] = useState(null);
    const [game3Option, setGame3Option] = useState(null);
    const [game4Option, setGame4Option] = useState(null);
    const [game5Option, setGame5Option] = useState(null);
    const [game6Option, setGame6Option] = useState(null);
    const [game7Option, setGame7Option] = useState(null);
    const [game8Option, setGame8Option] = useState(null);
    const [game9Option, setGame9Option] = useState(null);
    const [game10Option, setGame10Option] = useState(null);
    const [gameData, setGameData] = useState(new Array(10).fill(null));
    const [lockedRound, setLockedRound] = useState('');

    const betSucess = () => toast.success('Palpite realizado!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold',
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const betError = () => toast.error('Preencha todas as partidas!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold',
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const betErrorRepeatedId = () => toast.warn('Limite máximo de 10 palpites!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold',
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const betErrorValueOff = () => toast.error('Tickets insuficientes!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold',
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const handleOptionChange1 = (option) => {
        setGame1Option(option);
    }

    const handleOptionChange2 = (option) => {
        setGame2Option(option);
    }

    const handleOptionChange3 = (option) => {
        setGame3Option(option);
    }

    const handleOptionChange4 = (option) => {
        setGame4Option(option);
    }

    const handleOptionChange5 = (option) => {
        setGame5Option(option);
    }

    const handleOptionChange6 = (option) => {
        setGame6Option(option);
    }

    const handleOptionChange7 = (option) => {
        setGame7Option(option);
    }

    const handleOptionChange8 = (option) => {
        setGame8Option(option);
    }

    const handleOptionChange9 = (option) => {
        setGame9Option(option);
    }

    const handleOptionChange10 = (option) => {
        setGame10Option(option);
    }

    const backToHome = () => {
        navigate('/home');
    }

    const teams = {
        1: "Athletico-PR",
        2: "Atlético-GO",
        3: "Atlético-MG",
        4: "Bahia",
        5: "Botafogo",
        6: "Bragantino",
        7: "Corinthians",
        8: "Criciúma",
        9: "Cruzeiro",
        10: "Cuiabá",
        11: "Flamengo",
        12: "Fluminense",
        13: "Fortaleza",
        14: "Grêmio",
        15: "Internacional",
        16: "Juventude",
        17: "Palmeiras",
        18: "São Paulo",
        19: "Vasco",
        20: "Vitória",
        21: "Empate"
    };

    const getTeamImage = (teamNumber) => {
        switch (teamNumber) {
            case 1: return i1;
            case 2: return i2;
            case 3: return i3;
            case 4: return i4;
            case 5: return i5;
            case 6: return i6;
            case 7: return i7;
            case 8: return i8;
            case 9: return i9;
            case 10: return i10;
            case 11: return i11;
            case 12: return i12;
            case 13: return i13;
            case 14: return i14;
            case 15: return i15;
            case 16: return i16;
            case 17: return i17;
            case 18: return i18;
            case 19: return i19;
            case 20: return i20;
            default: return '';
        }
    };

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const betSaveValueDestructuring = async () => {
        const defaultIncome = 1;
        const defaultValue = 2;
        const geralPrizePercentage = 15;
        const roundPrizePercentage = 85;
        const geralPrize = (geralPrizePercentage / 100) * defaultValue;
        const roundPrize = (roundPrizePercentage / 100) * defaultValue;

        await updatePrizeCollection('default_income', defaultIncome);
        await updatePrizeCollection('geral_prize', geralPrize);
        await updatePrizeCollection('round_prize', roundPrize);
    };

    const updatePrizeCollection = async (collectionName, additionalValue) => {
        const db = getFirestore(app);
        const prizeCollection = doc(db, collectionName, 'prize');

        try {

            const prizeDocSnapshot = await getDoc(prizeCollection);
            const currentPrizeValue = prizeDocSnapshot.exists() ? prizeDocSnapshot.data().value : 0;

            const updatedValue = currentPrizeValue + additionalValue;
            await setDoc(prizeCollection, { value: updatedValue });
        } catch (error) {
            console.error('Err!');
        }
    };

    const fetchData = async () => {
        const db = getFirestore(app);

        const gamesRefs = Array.from({ length: 10 }, (_, index) =>
            doc(db, 'partidas', `Jogo ${index + 1}`)
        );

        try {
            const snapshots = await Promise.all(gamesRefs.map((gameRef) => getDoc(gameRef)));

            const gamesInfo = snapshots.map((docSnapshot) => {
                if (docSnapshot.exists()) {
                    return docSnapshot.data();
                } else {
                    console.log('Erro!');
                    return null;
                }
            });

            setGameData(gamesInfo);
        } catch (error) {
            console.error('Erro!');
        } finally {
            setLoading(true);
        }
    };

    const recuperarStatusRodada = async () => {
        const db = getFirestore(app);
        const statusRound = doc(db, 'locked_round', 'verify');

        try {
            const statusRoundSnapshsot = await getDoc(statusRound)
            if (statusRoundSnapshsot.exists()) {
                const rStatus = statusRoundSnapshsot.data();
                setLockedRound(rStatus.status)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Errr!');
        }
    };

    useEffect(() => {
        fetchData();
        recuperarStatusRodada();
    }, []);

    const subTicketsValue = async () => {
        const db = getFirestore(app);
        const userTkAlt = doc(db, 'user_data', vrf);

        try {
            const docSnapshot = await getDoc(userTkAlt);

            if (docSnapshot.exists()) {
                const userDataTicketsAlt = docSnapshot.data();
                const { tickets, ...otherFields } = userDataTicketsAlt;

                await setDoc(userTkAlt, {
                    ...otherFields,
                    tickets: tickets - 1,
                });
                betSucess();
            } else {
                console.log('Err!');
            }
        } catch (error) {
            console.error('Err!');
        }
    };


    const userBetOptions = [game1Option, game2Option, game3Option, game4Option, game5Option,
        game6Option, game7Option, game8Option, game9Option, game10Option]


    const saveBuyBet = async () => {
        const db = getFirestore(app);
        const userBetRef = doc(db, 'palpites', vrf);
        const userBetRefRept2 = doc(db, 'palpites', `${vrf}2`);
        const userBetRefRept3 = doc(db, 'palpites', `${vrf}3`);
        const userBetRefRept4 = doc(db, 'palpites', `${vrf}4`);
        const userBetRefRept5 = doc(db, 'palpites', `${vrf}5`);
        const userBetRefRept6 = doc(db, 'palpites', `${vrf}6`);
        const userBetRefRept7 = doc(db, 'palpites', `${vrf}7`);
        const userBetRefRept8 = doc(db, 'palpites', `${vrf}8`);
        const userBetRefRept9 = doc(db, 'palpites', `${vrf}9`);
        const userBetRefRept10 = doc(db, 'palpites', `${vrf}10`);

        const docSnapshot = await getDoc(userBetRef);
        const docSnapshot2 = await getDoc(userBetRefRept2);
        const docSnapshot3 = await getDoc(userBetRefRept3);
        const docSnapshot4 = await getDoc(userBetRefRept4);
        const docSnapshot5 = await getDoc(userBetRefRept5);
        const docSnapshot6 = await getDoc(userBetRefRept6);
        const docSnapshot7 = await getDoc(userBetRefRept7);
        const docSnapshot8 = await getDoc(userBetRefRept8);
        const docSnapshot9 = await getDoc(userBetRefRept9);
        const docSnapshot10 = await getDoc(userBetRefRept10);

        if (docSnapshot.exists()) {
            if (docSnapshot2.exists()) {
                if (docSnapshot3.exists()) {
                    if (docSnapshot4.exists()) {
                        if (docSnapshot5.exists()) {
                            if (docSnapshot6.exists()) {
                                if (docSnapshot7.exists()) {
                                    if (docSnapshot8.exists()) {
                                        if (docSnapshot9.exists()) {
                                            if (docSnapshot10.exists()) {
                                                betErrorRepeatedId();
                                            } else {
                                                try {
                                                    await setDoc(userBetRefRept10, {
                                                        palpites: userBetOptions,
                                                    });
                                                    const userParam = `${vrf}10`;
                                                    subTicketsValue();
                                                    makeUserRoundRanking(userParam);
                                                    betSaveValueDestructuring();
                                                } catch (error) {
                                                    console.error('Err!');
                                                }
                                            }
                                        } else {
                                            try {
                                                await setDoc(userBetRefRept9, {
                                                    palpites: userBetOptions,
                                                });
                                                const userParam = `${vrf}9`;
                                                subTicketsValue();
                                                makeUserRoundRanking(userParam);
                                                betSaveValueDestructuring();
                                            } catch (error) {
                                                console.error('Err!');
                                            }
                                        }
                                    } else {
                                        try {
                                            await setDoc(userBetRefRept8, {
                                                palpites: userBetOptions,
                                            });
                                            const userParam = `${vrf}8`;
                                            subTicketsValue();
                                            makeUserRoundRanking(userParam);
                                            betSaveValueDestructuring();
                                        } catch (error) {
                                            console.error('Err!');
                                        }
                                    }
                                } else {
                                    try {
                                        await setDoc(userBetRefRept7, {
                                            palpites: userBetOptions,
                                        });
                                        const userParam = `${vrf}7`;
                                        subTicketsValue();
                                        makeUserRoundRanking(userParam);
                                        betSaveValueDestructuring();
                                    } catch (error) {
                                        console.error('Err!');
                                    }
                                }
                            } else {
                                try {
                                    await setDoc(userBetRefRept6, {
                                        palpites: userBetOptions,
                                    });
                                    const userParam = `${vrf}6`;
                                    subTicketsValue();
                                    makeUserRoundRanking(userParam);
                                    betSaveValueDestructuring();
                                } catch (error) {
                                    console.error('Err!');
                                }
                            }
                        } else {
                            try {
                                await setDoc(userBetRefRept5, {
                                    palpites: userBetOptions,
                                });
                                const userParam = `${vrf}5`;
                                subTicketsValue();
                                makeUserRoundRanking(userParam);
                                betSaveValueDestructuring();
                            } catch (error) {
                                console.error('Err!');
                            }
                        }
                    } else {
                        try {
                            await setDoc(userBetRefRept4, {
                                palpites: userBetOptions,
                            });
                            const userParam = `${vrf}4`;
                            subTicketsValue();
                            makeUserRoundRanking(userParam);
                            betSaveValueDestructuring();
                        } catch (error) {
                            console.error('Err!');
                        }
                    }
                } else {
                    try {
                        await setDoc(userBetRefRept3, {
                            palpites: userBetOptions,
                        });
                        const userParam = `${vrf}3`;
                        subTicketsValue();
                        makeUserRoundRanking(userParam);
                        betSaveValueDestructuring();
                    } catch (error) {
                        console.error('Err!');
                    }
                }
            } else {
                try {
                    await setDoc(userBetRefRept2, {
                        palpites: userBetOptions,
                    });
                    const userParam = `${vrf}2`;
                    subTicketsValue();
                    makeUserRoundRanking(userParam);
                    betSaveValueDestructuring();
                } catch (error) {
                    console.error('Err!');
                }
            }
        } else {
            try {
                await setDoc(userBetRef, {
                    palpites: userBetOptions,
                });
                const userParam = vrf;
                subTicketsValue();
                makeUserRoundRanking(userParam);
                betSaveValueDestructuring();
            } catch (error) {
                console.error('Err!');
            }
        }
    };

    const makeUserRoundRanking = async (userParam) => {
        const db = getFirestore(app);
        const newUserRanking = doc(db, 'round_ranking', userParam);
        try {
            await setDoc(newUserRanking, {
                points: 0,
            });
        } catch (error) {
            console.error('Err!');
        }
    };

    const apagaSelecao = () => {
        setGame1Option(null);
        setGame2Option(null);
        setGame3Option(null);
        setGame4Option(null);
        setGame5Option(null);
        setGame6Option(null);
        setGame7Option(null);
        setGame8Option(null);
        setGame9Option(null);
        setGame10Option(null);
    }

    const vrfRadiosCheck = () => {
        if (game1Option !== null && game2Option !== null && game3Option !== null && game4Option !== null &&
            game5Option !== null && game6Option !== null && game7Option !== null && game8Option !== null &&
            game9Option !== null && game10Option !== null) {
            saveBuyBet();
            apagaSelecao();
            setTimeout(() => {
                navigate('/home');
            }, 3000);
        } else {
            betError();
        }
    }

    const ticketValueVrf = async () => {
        const db = getFirestore(app);
        const userDocument = doc(db, 'user_data', vrf);

        try {
            const docSnapshot = await getDoc(userDocument);
            if (docSnapshot.exists()) {
                const userDataTickets = docSnapshot.data();
                const ticketsValue = userDataTickets.tickets;

                if (ticketsValue < 1) {
                    betErrorValueOff();
                } else {
                    vrfRadiosCheck();
                }
            } else {
                console.log('Err!');
            }
        } catch (error) {
            console.error('Err!');
        }
    };

    if (loading) {

        return (
            <section>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

                <header className="navbar-bet">
                    <h3>Rodada 1</h3>
                    <div className="back-ico">
                        <IoChevronBackCircleSharp onClick={backToHome} size={30} color="#FFF" />
                    </div>
                </header>

                <div className="spacing"></div>
                <div className="infoOptions">
                    <span>Casa</span>
                    <span>Empate</span>
                    <span>Fora</span>
                </div>

                <div className="partidas">
                    <div className="partida">
                        <div><h3>Jogo 1</h3></div>
                        <div><h4>{gameData[0]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[0]?.casa)} />
                                <p className="teamName">{teams[gameData[0]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game1"
                                    value="1"
                                    checked={game1Option === 1}
                                    onChange={() => handleOptionChange1(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game1"
                                    value="2"
                                    checked={game1Option === 2}
                                    onChange={() => handleOptionChange1(2)}
                                />
                                <input
                                    type="radio"
                                    name="game1"
                                    value="3"
                                    checked={game1Option === 3}
                                    onChange={() => handleOptionChange1(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[0]?.visitante)} />
                                <p className="teamName">{teams[gameData[0]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 2</h3></div>
                        <div><h4>{gameData[1]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[1]?.casa)} />
                                <p className="teamName">{teams[gameData[1]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game2"
                                    value="1"
                                    checked={game2Option === 1}
                                    onChange={() => handleOptionChange2(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game2"
                                    value="2"
                                    checked={game2Option === 2}
                                    onChange={() => handleOptionChange2(2)}
                                />
                                <input
                                    type="radio"
                                    name="game2"
                                    value="3"
                                    checked={game2Option === 3}
                                    onChange={() => handleOptionChange2(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[1]?.visitante)} />
                                <p className="teamName">{teams[gameData[1]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 3</h3></div>
                        <div><h4>{gameData[2]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[2]?.casa)} />
                                <p className="teamName">{teams[gameData[2]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game3"
                                    value="1"
                                    checked={game3Option === 1}
                                    onChange={() => handleOptionChange3(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game3"
                                    value="2"
                                    checked={game3Option === 2}
                                    onChange={() => handleOptionChange3(2)}
                                />
                                <input
                                    type="radio"
                                    name="game3"
                                    value="3"
                                    checked={game3Option === 3}
                                    onChange={() => handleOptionChange3(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[2]?.visitante)} />
                                <p className="teamName">{teams[gameData[2]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 4</h3></div>
                        <div><h4>{gameData[3]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[3]?.casa)} />
                                <p className="teamName">{teams[gameData[3]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game4"
                                    value="1"
                                    checked={game4Option === 1}
                                    onChange={() => handleOptionChange4(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game4"
                                    value="2"
                                    checked={game4Option === 2}
                                    onChange={() => handleOptionChange4(2)}
                                />
                                <input
                                    type="radio"
                                    name="game4"
                                    value="3"
                                    checked={game4Option === 3}
                                    onChange={() => handleOptionChange4(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[3]?.visitante)} />
                                <p className="teamName">{teams[gameData[3]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 5</h3></div>
                        <div><h4>{gameData[4]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[4]?.casa)} />
                                <p className="teamName">{teams[gameData[4]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game5"
                                    value="1"
                                    checked={game5Option === 1}
                                    onChange={() => handleOptionChange5(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game5"
                                    value="2"
                                    checked={game5Option === 2}
                                    onChange={() => handleOptionChange5(2)}
                                />
                                <input
                                    type="radio"
                                    name="game5"
                                    value="3"
                                    checked={game5Option === 3}
                                    onChange={() => handleOptionChange5(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[4]?.visitante)} />
                                <p className="teamName">{teams[gameData[4]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 6</h3></div>
                        <div><h4>{gameData[5]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[5]?.casa)} />
                                <p className="teamName">{teams[gameData[5]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game6"
                                    value="1"
                                    checked={game6Option === 1}
                                    onChange={() => handleOptionChange6(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game6"
                                    value="2"
                                    checked={game6Option === 2}
                                    onChange={() => handleOptionChange6(2)}
                                />
                                <input
                                    type="radio"
                                    name="game6"
                                    value="3"
                                    checked={game6Option === 3}
                                    onChange={() => handleOptionChange6(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[5]?.visitante)} />
                                <p className="teamName">{teams[gameData[5]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 7</h3></div>
                        <div><h4>{gameData[6]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[6]?.casa)} />
                                <p className="teamName">{teams[gameData[6]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game7"
                                    value="1"
                                    checked={game7Option === 1}
                                    onChange={() => handleOptionChange7(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game7"
                                    value="2"
                                    checked={game7Option === 2}
                                    onChange={() => handleOptionChange7(2)}
                                />
                                <input
                                    type="radio"
                                    name="game7"
                                    value="3"
                                    checked={game7Option === 3}
                                    onChange={() => handleOptionChange7(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[6]?.visitante)} />
                                <p className="teamName">{teams[gameData[6]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 8</h3></div>
                        <div><h4>{gameData[7]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[7]?.casa)} />
                                <p className="teamName">{teams[gameData[7]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game8"
                                    value="1"
                                    checked={game8Option === 1}
                                    onChange={() => handleOptionChange8(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game8"
                                    value="2"
                                    checked={game8Option === 2}
                                    onChange={() => handleOptionChange8(2)}
                                />
                                <input
                                    type="radio"
                                    name="game8"
                                    value="3"
                                    checked={game8Option === 3}
                                    onChange={() => handleOptionChange8(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[7]?.visitante)} />
                                <p className="teamName">{teams[gameData[7]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 9</h3></div>
                        <div><h4>{gameData[8]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[8]?.casa)} />
                                <p className="teamName">{teams[gameData[8]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game9"
                                    value="1"
                                    checked={game9Option === 1}
                                    onChange={() => handleOptionChange9(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game9"
                                    value="2"
                                    checked={game9Option === 2}
                                    onChange={() => handleOptionChange9(2)}
                                />
                                <input
                                    type="radio"
                                    name="game9"
                                    value="3"
                                    checked={game9Option === 3}
                                    onChange={() => handleOptionChange9(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[8]?.visitante)} />
                                <p className="teamName">{teams[gameData[8]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="partida">
                        <div><h3>Jogo 10</h3></div>
                        <div><h4>{gameData[9]?.gps ?? ''}</h4></div>
                        <div className="options">
                            <div>
                                <img src={getTeamImage(gameData[9]?.casa)} />
                                <p className="teamName">{teams[gameData[9]?.casa] ?? ''}</p>
                            </div>
                            <div className="radios-chk">
                                <input
                                    type="radio"
                                    name="game10"
                                    value="1"
                                    checked={game10Option === 1}
                                    onChange={() => handleOptionChange10(1)}
                                />
                                <input className="input-space"
                                    type="radio"
                                    name="game10"
                                    value="2"
                                    checked={game10Option === 2}
                                    onChange={() => handleOptionChange10(2)}
                                />
                                <input
                                    type="radio"
                                    name="game10"
                                    value="3"
                                    checked={game10Option === 3}
                                    onChange={() => handleOptionChange10(3)}
                                /><label>X</label>
                            </div>
                            <div>
                                <img src={getTeamImage(gameData[9]?.visitante)} />
                                <p className="teamName">{teams[gameData[9]?.visitante] ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    {lockedRound === false ? (

                        <div className="btn-palpitar">
                            <div className="btn-make-bet">
                                <h2 onClick={ticketValueVrf}>Palpitar</h2>
                            </div>
                        </div>

                    ) : (

                        <div className="div-alert">
                            <p className='alert'>Rodada Fechada!</p>
                        </div>

                    )
                    }



                </div>

            </section >
        )
    }
}

export default Bet;