import React, { useState, useEffect } from "react";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckboxOutline } from "react-icons/io";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import divisor from '../img/cfg_div.png'
import i1 from '../img/athleticopr.svg';
import i2 from '../img/atleticogo.svg';
import i3 from '../img/atleticomg.svg';
import i4 from '../img/bahia.svg';
import i5 from '../img/botafogo.svg';
import i6 from '../img/bragantino.svg';
import i7 from '../img/corinthians.svg';
import i8 from '../img/criciuma.svg';
import i9 from '../img/cruzeiro.svg';
import i10 from '../img/cuiaba.svg';
import i11 from '../img/flamengo.svg';
import i12 from '../img/fluminense.svg';
import i13 from '../img/fortaleza.svg';
import i14 from '../img/gremio.svg';
import i15 from '../img/internacional.svg';
import i16 from '../img/juventude.svg';
import i17 from '../img/palmeiras.svg';
import i18 from '../img/saopaulo.svg';
import i19 from '../img/vasco.svg';
import i20 from '../img/vitoria.svg';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AdmConfig = () => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [game1Option, setGame1Option] = useState(0);
    const [game2Option, setGame2Option] = useState(0);
    const [game3Option, setGame3Option] = useState(0);
    const [game4Option, setGame4Option] = useState(0);
    const [game5Option, setGame5Option] = useState(0);
    const [game6Option, setGame6Option] = useState(0);
    const [game7Option, setGame7Option] = useState(0);
    const [game8Option, setGame8Option] = useState(0);
    const [game9Option, setGame9Option] = useState(0);
    const [game10Option, setGame10Option] = useState(0);
    const [gameData, setGameData] = useState(new Array(10).fill(null));
    const [inputContato, setInputContato] = useState('');
    const [inputNomeCompleto, setInputNomeCompleto] = useState('');
    const [inputUsuario, setInputUsuario] = useState('');
    const [inputPix, setInputPix] = useState('');
    const [inputSenha, setInputSenha] = useState('');
    const [inputFoto, setInputFoto] = useState(null);
    const [qntUsuarios, setQntUsuarios] = useState('');
    const [inputID, setInputID] = useState('00');
    const [IDs, setIDs] = useState([]);
    const [idSelecionado, setIdSelecionado] = useState('');
    const [selectedUserTickets, setSelectedUserTickets] = useState(0);
    const [newSaldoTickets, setNewSaldoTickets] = useState(selectedUserTickets);
    const [lockedRound, setLockedRound] = useState('');
    const [roundResultFinded, setRoundResultFinded] = useState([]);
    const [roundUsersBetData, setroundUsersBetData] = useState([]);
    const [atualizaPontos, setAtualizaPontos] = useState(false);
    const [pontuacaoAtualizada, setPontuacaoAtualizada] = useState([]);
    const [dadosJogos, setDadosJogos] = useState({
        'Jogo 1': { casa: '', visitante: '', gps: '' },
        'Jogo 2': { casa: '', visitante: '', gps: '' },
        'Jogo 3': { casa: '', visitante: '', gps: '' },
        'Jogo 4': { casa: '', visitante: '', gps: '' },
        'Jogo 5': { casa: '', visitante: '', gps: '' },
        'Jogo 6': { casa: '', visitante: '', gps: '' },
        'Jogo 7': { casa: '', visitante: '', gps: '' },
        'Jogo 8': { casa: '', visitante: '', gps: '' },
        'Jogo 9': { casa: '', visitante: '', gps: '' },
        'Jogo 10': { casa: '', visitante: '', gps: '' },
    });

    useEffect(() => {
        setNewSaldoTickets(selectedUserTickets);
    }, [selectedUserTickets]);

    const formatedInputID = 'p' + inputID + 'a'

    const attSucess = () => toast.success('Dados Atualizados!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold',
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const reloadPage = () => {
        attSucess();
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    };

    const contarUsuarios = async () => {
        const db = getFirestore(app);
        const contagemDeUsuarios = collection(db, 'user_data');

        try {
            const contagemDeUsuariosSnapshot = await getDocs(contagemDeUsuarios)
            const contagem = contagemDeUsuariosSnapshot.size;
            setQntUsuarios(contagem);

            const idsDosUsuarios = contagemDeUsuariosSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
            }));
            setIDs(idsDosUsuarios);
        }
        catch (error) {
            console.error('Error!');
        }
    };

    const recuperarResultados = async () => {
        const db = getFirestore(app);
        const roundResultDocument = doc(db, 'round_result', 'result');

        try {
            const roundResultSnapshot = await getDoc(roundResultDocument)
            if (roundResultSnapshot.exists()) {
                const dataBetResults = roundResultSnapshot.data();
                setGame1Option(dataBetResults.games[0]);
                setGame2Option(dataBetResults.games[1]);
                setGame3Option(dataBetResults.games[2]);
                setGame4Option(dataBetResults.games[3]);
                setGame5Option(dataBetResults.games[4]);
                setGame6Option(dataBetResults.games[5]);
                setGame7Option(dataBetResults.games[6]);
                setGame8Option(dataBetResults.games[7]);
                setGame9Option(dataBetResults.games[8]);
                setGame10Option(dataBetResults.games[9]);
            } else {
                console.log('Erro!');
            }

        } catch (error) {
            console.error('Error!');
        }
    };

    const recuperaDadosPartidas = async () => {
        const db = getFirestore(app);

        const gamesRefs = Array.from({ length: 10 }, (_, index) =>
            doc(db, 'partidas', `Jogo ${index + 1}`)
        );

        try {
            const snapshots = await Promise.all(gamesRefs.map((gameRef) => getDoc(gameRef)));

            const gamesInfo = snapshots.map((docSnapshot) => {
                if (docSnapshot.exists()) {
                    return docSnapshot.data();
                } else {
                    console.log('Erro!');
                    return null;
                }
            });

            setGameData(gamesInfo);
        } catch (error) {
            console.error('Erro!');
        }
    };

    const recuperarStatusRodada = async () => {
        const db = getFirestore(app);
        const statusRound = doc(db, 'locked_round', 'verify');

        try {
            const statusRoundSnapshsot = await getDoc(statusRound)
            if (statusRoundSnapshsot.exists()) {
                const rStatus = statusRoundSnapshsot.data();
                setLockedRound(rStatus.status)
            } else {
                console.log('Err!');
            }

        } catch (error) {
            console.error('Error!');
        }
    };

    const recuperarResultadosReais = async () => {
        const db = getFirestore(app);
        const roundResultDocument = doc(db, 'round_result', 'result');
        const roundResultDocSnapshot = await getDoc(roundResultDocument);

        try {
            if (roundResultDocSnapshot.exists()) {
                setRoundResultFinded(roundResultDocSnapshot.data());
            } else {
                console.log('Erro!');
            }

        } catch (error) {
            console.error('Error!');
        }
    };

    const recuperarResultadosDosUsuarios = async () => {
        const db = getFirestore(app);
        const userBetCollection = collection(db, 'palpites');

        try {
            const userBetSnapshot = await getDocs(userBetCollection);

            if (!userBetSnapshot.empty) {
                const userBetData = {};

                userBetSnapshot.forEach(doc => {
                    const dataBet = doc.data();
                    const documentId = doc.id;
                    userBetData[documentId] = dataBet.palpites;
                });

                setroundUsersBetData(userBetData);
            } else {
                console.log('No_Document!');
            }
        } catch (error) {
            console.error('Err!');
        }
    };

    const validacaoResultados = () => {
        const verificacaoRealizada = {};

        // Itera sobre cada usuário em roundUsersBetData
        for (const userId in roundUsersBetData) {
            if (roundUsersBetData.hasOwnProperty(userId)) {
                const palpitesUsuario = roundUsersBetData[userId];

                // Inicializa a contagem de correspondências para o usuário atual
                let correspondencias = 0;

                // Itera sobre cada jogo nos palpites do usuário
                for (let i = 0; i < palpitesUsuario.length; i++) {
                    // Verifica se o palpite do usuário é igual ao resultado
                    if (palpitesUsuario[i] === roundResultFinded.games[i]) {
                        correspondencias++;
                    }
                }
                // Armazena o resultado da verificação para o usuário atual
                verificacaoRealizada[userId] = correspondencias;
                atualizaPontuacao(userId, correspondencias);
            }
        }
    };

    const recuperarPontuacaoAtualizada = async () => {
        const db = getFirestore(app);
        const actualizedPoints = collection(db, 'round_ranking');

        try {
            const actualizedPointsSnapshot = await getDocs(actualizedPoints);

            const dataPontuacao = {};

            actualizedPointsSnapshot.forEach((doc) => {
                if (doc.exists()) {
                    const userId = doc.id;
                    const userData = doc.data();
                    dataPontuacao[userId] = userData.points;
                }
            });

            setPontuacaoAtualizada(dataPontuacao);
        } catch (error) {
            console.error('Err!');
        }
    };

    useEffect(() => {
        recuperarResultados();
        recuperaDadosPartidas();
        contarUsuarios();
        recuperarStatusRodada();
        recuperarResultadosReais();
        recuperarResultadosDosUsuarios();
        recuperarPontuacaoAtualizada();
        setLoading(true);
    }, []);

    const gamesAtualization = [game1Option, game2Option, game3Option, game4Option, game5Option,
        game6Option, game7Option, game8Option, game9Option, game10Option]

    const saveResultBet = async () => {
        const db = getFirestore(app);
        const liveResult = doc(db, 'round_result', 'result');
        try {
            await setDoc(liveResult, {
                games: gamesAtualization,
            });
        } catch (error) {
            console.error('Err!');
        }
        setAtualizaPontos(true);
    };

    const makeNewUser = async () => {
        const db = getFirestore(app);
        const newUserData = doc(db, 'user_data', formatedInputID);
        try {
            await setDoc(newUserData, {
                contact: inputContato,
                full_name: inputNomeCompleto,
                name: inputUsuario,
                pix_key: inputPix,
                tickets: 0,
                imageUrl: inputFoto,
            });
        } catch (error) {
            console.error('Err!');
        }
    };

    const makePasswordUser = async () => {
        const db = getFirestore(app);
        const newUserPassword = doc(db, 'users_Logins', formatedInputID);
        try {
            await setDoc(newUserPassword, {
                password: inputSenha,
            });
        } catch (error) {
            console.error('Err!');
        }
    };

    const makeUserGeralRanking = async () => {
        const db = getFirestore(app);
        const newUserRanking = doc(db, 'geral_ranking', formatedInputID);
        try {
            await setDoc(newUserRanking, {
                points: 0,
            });
        } catch (error) {
            console.error('Err!');
        }
    };

    const newUser = () => {
        makePasswordUser();
        makeNewUser();
        makeUserGeralRanking();
        reloadPage();
    }

    const newRoundStatus = async (status) => {
        const db = getFirestore(app);
        const newStatusRound = doc(db, 'locked_round', 'verify');

        try {
            await setDoc(newStatusRound, {
                status: status,
            });
            reloadPage();
        } catch (error) {
            console.error('Err!');
        }
    };

    const atualizaPontuacao = async (userId, pontos) => {
        const db = getFirestore(app);
        const pontuacaoCollection = doc(db, 'round_ranking', userId);

        try {
            await setDoc(pontuacaoCollection, {
                points: pontos,
            });
            attSucess();
        } catch (error) {
            console.error('Err!');
        }
    };

    const atualizaRankingGeral = async () => {
        const db = getFirestore(app);

        try {
            for (const userId in pontuacaoAtualizada) {
                if (pontuacaoAtualizada.hasOwnProperty(userId)) {
                    const pontuacaoGeralDocRef = doc(db, 'geral_ranking', userId);

                    const pontuacaoGeralSnapshot = await getDoc(pontuacaoGeralDocRef);

                    if (pontuacaoGeralSnapshot.exists()) {
                        const dataGeralPontuacao = pontuacaoGeralSnapshot.data();
                        dataGeralPontuacao.points += pontuacaoAtualizada[userId];

                        await setDoc(pontuacaoGeralDocRef, dataGeralPontuacao);
                    } else {
                        await setDoc(pontuacaoGeralDocRef, { points: pontuacaoAtualizada[userId] });
                    }
                }
            }
        } catch (error) {
            console.error('Err!');
        }
    };

    const zeraResultados = async () => {
        const db = getFirestore(app);
        const resultsCollection = doc(db, 'round_result', 'result');
        const games = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        try {
            const resultsCollectionSnapshot = await getDoc(resultsCollection);

            if (resultsCollectionSnapshot.exists()) {
                const resultsData = resultsCollectionSnapshot.data();

                resultsData.games = games;

                await setDoc(resultsCollection, resultsData);

            } else {
                console.log('Err!');
            }
        } catch (error) {
            console.error('Err!');
        }
    };

    const zeraRoundRanking = async () => {
        const db = getFirestore(app);
        const roundRankingCollection = collection(db, 'round_ranking');

        try {
            const roundRankingDocs = await getDocs(roundRankingCollection);

            roundRankingDocs.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

        } catch (error) {
            console.error('Err!');
        }
    };

    const zeraRoundBets = async () => {
        const db = getFirestore(app);
        const roundBetsCollection = collection(db, 'palpites');

        try {
            const roundBetsDocs = await getDocs(roundBetsCollection);

            roundBetsDocs.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

        } catch (error) {
            console.error('Err!');
        }
    };

    const zeraPremioRodada = async () => {
        const db = getFirestore(app);
        const prizeRoundCollection = doc(db, 'round_prize', 'prize');
        const value = 0;

        try {
            const prizeRoundCollectionSnapshot = await getDoc(prizeRoundCollection);

            if (prizeRoundCollectionSnapshot.exists()) {
                const prizeRoundData = prizeRoundCollectionSnapshot.data();

                prizeRoundData.value = value;

                await setDoc(prizeRoundCollection, prizeRoundData);

                reloadPage();
            } else {
                console.log('Err!');
            }
        } catch (error) {
            console.error('Err!');
        }
    }

    const mudaNumeroRodada = async () => {
        const db = getFirestore(app);
        const roundNumberCollection = doc(db, 'round_number', 'round');

        try {
            const roundNumberCollectionSnapshot = await getDoc(roundNumberCollection);

            if (roundNumberCollectionSnapshot.exists()) {
                const dataAtualRound = roundNumberCollectionSnapshot.data();
                const atualRoundNumber = dataAtualRound.number;

                const somaRound = atualRoundNumber + 1;

                await updateDoc(roundNumberCollection, { number: somaRound });

                reloadPage();
            } else {
                console.log('Err!');
            }
        } catch (error) {
            console.error('Err!', error);
        }
    };

    const fecharMudarRodada = async () => {
        await atualizaRankingGeral();
        await zeraResultados();
        await zeraRoundRanking();
        await zeraRoundBets();
        await mudaNumeroRodada();
        reloadPage();
    }

    const handleOptionChange1 = (option) => {
        setGame1Option(option);
    }

    const handleOptionChange2 = (option) => {
        setGame2Option(option);
    }

    const handleOptionChange3 = (option) => {
        setGame3Option(option);
    }

    const handleOptionChange4 = (option) => {
        setGame4Option(option);
    }

    const handleOptionChange5 = (option) => {
        setGame5Option(option);
    }

    const handleOptionChange6 = (option) => {
        setGame6Option(option);
    }

    const handleOptionChange7 = (option) => {
        setGame7Option(option);
    }

    const handleOptionChange8 = (option) => {
        setGame8Option(option);
    }

    const handleOptionChange9 = (option) => {
        setGame9Option(option);
    }

    const handleOptionChange10 = (option) => {
        setGame10Option(option);
    }

    const handleChange = (jogo, campo, valor) => {
        setDadosJogos((prevDados) => ({
            ...prevDados,
            [jogo]: {
                ...prevDados[jogo],
                [campo]: campo === 'gps' ? valor : Number(valor),
            },
        }));
    };

    const handleAtualizaPontos = () => {
        validacaoResultados();
        setTimeout(() => {
            reloadPage();
        }, 5000)
    }

    const bloquearRodada = () => {
        setLockedRound(true);
        newRoundStatus(true);
    };

    const desbloquearRodada = () => {
        setLockedRound(false);
        newRoundStatus(false);
    };

    const incrementValue = () => {
        setNewSaldoTickets(newSaldoTickets + 1);
    }

    const decrementValue = () => {
        setNewSaldoTickets(newSaldoTickets - 1);
    }

    const recuperarSaldoTickets = async () => {
        const db = getFirestore(app);
        const ticketsSelectedUser = doc(db, 'user_data', idSelecionado);

        try {
            const ticketsSelectedUserSnapshot = await getDoc(ticketsSelectedUser)
            if (ticketsSelectedUserSnapshot.exists()) {
                const ticketsAtualValue = ticketsSelectedUserSnapshot.data();
                setSelectedUserTickets(ticketsAtualValue.tickets)
            } else {
                console.log('No_User!');
            }

        } catch (error) {
            console.error('Error!');
        }
    };

    const novoSaldoTickets = async () => {
        const db = getFirestore(app);
        const userDocumentRefTickets = doc(db, 'user_data', idSelecionado);

        try {
            const userDocumentRefTicketsSnapshot = await getDoc(userDocumentRefTickets);

            if (userDocumentRefTicketsSnapshot.exists()) {
                const userData = userDocumentRefTicketsSnapshot.data();
                const { tickets, ...otherFields } = userData;

                await setDoc(userDocumentRefTickets, {
                    ...otherFields,
                    tickets: newSaldoTickets,
                });
                reloadPage();
            } else {
                console.log('Err!');
            }
        } catch (error) {
            console.error('Err!');
        }
    };

    const alterarPartidas = async () => {
        const db = getFirestore(app);
        const partidasCollection = collection(db, 'partidas');

        try {

            for (const [jogo, dados] of Object.entries(dadosJogos)) {
                const partidaDocRef = doc(partidasCollection, jogo);

                await setDoc(partidaDocRef, dados, { merge: true });
            }

            reloadPage();
        } catch (error) {
            console.error('Err!');
        }
    };

    const teams = {
        1: "Athletico-PR",
        2: "Atlético-GO",
        3: "Atlético-MG",
        4: "Bahia",
        5: "Botafogo",
        6: "Bragantino",
        7: "Corinthians",
        8: "Criciúma",
        9: "Cruzeiro",
        10: "Cuiabá",
        11: "Flamengo",
        12: "Fluminense",
        13: "Fortaleza",
        14: "Grêmio",
        15: "Internacional",
        16: "Juventude",
        17: "Palmeiras",
        18: "São Paulo",
        19: "Vasco",
        20: "Vitória",
        21: "Empate"
    };

    const getTeamImage = (teamNumber) => {
        switch (teamNumber) {
            case 1: return i1;
            case 2: return i2;
            case 3: return i3;
            case 4: return i4;
            case 5: return i5;
            case 6: return i6;
            case 7: return i7;
            case 8: return i8;
            case 9: return i9;
            case 10: return i10;
            case 11: return i11;
            case 12: return i12;
            case 13: return i13;
            case 14: return i14;
            case 15: return i15;
            case 16: return i16;
            case 17: return i17;
            case 18: return i18;
            case 19: return i19;
            case 20: return i20;
            default: return '';
        }
    };

    if (loading) {

        return (
            <section>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="saudation">
                    <h1>Painel do <strong>Administrador</strong>!</h1>
                </div>

                <div className="cfg-container">

                    <div className="cfg-title">
                        <h1>• RESULTADOS</h1>
                    </div>

                    <div className="cfg-partidas">
                        <div className="partida">
                            <div><h3>Jogo 1</h3></div>
                            <div><h4>{gameData[0]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[0]?.casa)} />
                                    <p className="teamName">{teams[gameData[0]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game1"
                                        value="1"
                                        checked={game1Option === 1}
                                        onChange={() => handleOptionChange1(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game1"
                                        value="2"
                                        checked={game1Option === 2}
                                        onChange={() => handleOptionChange1(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game1"
                                        value="3"
                                        checked={game1Option === 3}
                                        onChange={() => handleOptionChange1(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[0]?.visitante)} />
                                    <p className="teamName">{teams[gameData[0]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 2</h3></div>
                            <div><h4>{gameData[1]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[1]?.casa)} />
                                    <p className="teamName">{teams[gameData[1]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game2"
                                        value="1"
                                        checked={game2Option === 1}
                                        onChange={() => handleOptionChange2(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game2"
                                        value="2"
                                        checked={game2Option === 2}
                                        onChange={() => handleOptionChange2(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game2"
                                        value="3"
                                        checked={game2Option === 3}
                                        onChange={() => handleOptionChange2(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[1]?.visitante)} />
                                    <p className="teamName">{teams[gameData[1]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 3</h3></div>
                            <div><h4>{gameData[2]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[2]?.casa)} />
                                    <p className="teamName">{teams[gameData[2]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game3"
                                        value="1"
                                        checked={game3Option === 1}
                                        onChange={() => handleOptionChange3(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game3"
                                        value="2"
                                        checked={game3Option === 2}
                                        onChange={() => handleOptionChange3(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game3"
                                        value="3"
                                        checked={game3Option === 3}
                                        onChange={() => handleOptionChange3(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[2]?.visitante)} />
                                    <p className="teamName">{teams[gameData[2]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 4</h3></div>
                            <div><h4>{gameData[3]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[3]?.casa)} />
                                    <p className="teamName">{teams[gameData[3]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game4"
                                        value="1"
                                        checked={game4Option === 1}
                                        onChange={() => handleOptionChange4(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game4"
                                        value="2"
                                        checked={game4Option === 2}
                                        onChange={() => handleOptionChange4(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game4"
                                        value="3"
                                        checked={game4Option === 3}
                                        onChange={() => handleOptionChange4(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[3]?.visitante)} />
                                    <p className="teamName">{teams[gameData[3]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 5</h3></div>
                            <div><h4>{gameData[4]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[4]?.casa)} />
                                    <p className="teamName">{teams[gameData[4]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game5"
                                        value="1"
                                        checked={game5Option === 1}
                                        onChange={() => handleOptionChange5(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game5"
                                        value="2"
                                        checked={game5Option === 2}
                                        onChange={() => handleOptionChange5(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game5"
                                        value="3"
                                        checked={game5Option === 3}
                                        onChange={() => handleOptionChange5(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[4]?.visitante)} />
                                    <p className="teamName">{teams[gameData[4]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 6</h3></div>
                            <div><h4>{gameData[5]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[5]?.casa)} />
                                    <p className="teamName">{teams[gameData[5]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game6"
                                        value="1"
                                        checked={game6Option === 1}
                                        onChange={() => handleOptionChange6(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game6"
                                        value="2"
                                        checked={game6Option === 2}
                                        onChange={() => handleOptionChange6(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game6"
                                        value="3"
                                        checked={game6Option === 3}
                                        onChange={() => handleOptionChange6(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[5]?.visitante)} />
                                    <p className="teamName">{teams[gameData[5]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 7</h3></div>
                            <div><h4>{gameData[6]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[6]?.casa)} />
                                    <p className="teamName">{teams[gameData[6]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game7"
                                        value="1"
                                        checked={game7Option === 1}
                                        onChange={() => handleOptionChange7(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game7"
                                        value="2"
                                        checked={game7Option === 2}
                                        onChange={() => handleOptionChange7(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game7"
                                        value="3"
                                        checked={game7Option === 3}
                                        onChange={() => handleOptionChange7(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[6]?.visitante)} />
                                    <p className="teamName">{teams[gameData[6]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 8</h3></div>
                            <div><h4>{gameData[7]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[7]?.casa)} />
                                    <p className="teamName">{teams[gameData[7]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game8"
                                        value="1"
                                        checked={game8Option === 1}
                                        onChange={() => handleOptionChange8(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game8"
                                        value="2"
                                        checked={game8Option === 2}
                                        onChange={() => handleOptionChange8(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game8"
                                        value="3"
                                        checked={game8Option === 3}
                                        onChange={() => handleOptionChange8(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[7]?.visitante)} />
                                    <p className="teamName">{teams[gameData[7]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 9</h3></div>
                            <div><h4>{gameData[8]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[8]?.casa)} />
                                    <p className="teamName">{teams[gameData[8]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game9"
                                        value="1"
                                        checked={game9Option === 1}
                                        onChange={() => handleOptionChange9(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game9"
                                        value="2"
                                        checked={game9Option === 2}
                                        onChange={() => handleOptionChange9(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game9"
                                        value="3"
                                        checked={game9Option === 3}
                                        onChange={() => handleOptionChange9(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[8]?.visitante)} />
                                    <p className="teamName">{teams[gameData[8]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="partida">
                            <div><h3>Jogo 10</h3></div>
                            <div><h4>{gameData[9]?.gps ?? ''}</h4></div>
                            <div className="options">
                                <div>
                                    <img src={getTeamImage(gameData[9]?.casa)} />
                                    <p className="teamName">{teams[gameData[9]?.casa] ?? ''}</p>
                                </div>
                                <div className="radios-chk">
                                    <input
                                        type="radio"
                                        name="game10"
                                        value="1"
                                        checked={game10Option === 1}
                                        onChange={() => handleOptionChange10(1)}
                                    />
                                    <input className="input-space"
                                        type="radio"
                                        name="game10"
                                        value="2"
                                        checked={game10Option === 2}
                                        onChange={() => handleOptionChange10(2)}
                                    />
                                    <input
                                        type="radio"
                                        name="game10"
                                        value="3"
                                        checked={game10Option === 3}
                                        onChange={() => handleOptionChange10(3)}
                                    /><label>X</label>
                                </div>
                                <div>
                                    <img src={getTeamImage(gameData[9]?.visitante)} />
                                    <p className="teamName">{teams[gameData[9]?.visitante] ?? ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="att-points">
                        <p>Atualiza Pontos?</p>

                        {atualizaPontos === false ? (
                            <span><MdCheckBoxOutlineBlank onClick={saveResultBet} /></span>
                        ) : (
                            <span><IoMdCheckboxOutline /></span>
                        )}

                    </div>

                    <div className="btn-config" onClick={handleAtualizaPontos}>
                        <h2>ATUALIZAR</h2>
                    </div>

                    <div className="divisor"><img src={divisor} /></div>

                    <div className="cfg-title">
                        <h1>• CRIAR USUÁRIO</h1>
                    </div>

                    <div className="make-new-user">
                        <div className="contador">
                            <h2>Usuários: {qntUsuarios}</h2>
                        </div>

                        <div>
                            <label>ID</label>
                            <input type="text" value={inputID}
                                onChange={(e) => setInputID(e.target.value)} />
                        </div>

                        <div>
                            <label>Contato</label>
                            <input type="text" value={inputContato}
                                onChange={(e) => setInputContato(e.target.value)} />
                        </div>

                        <div>
                            <label>Nome Completo</label>
                            <input type="text" value={inputNomeCompleto}
                                onChange={(e) => setInputNomeCompleto(e.target.value)} />
                        </div>

                        <div>
                            <label>Usuário</label>
                            <input type="text" value={inputUsuario}
                                onChange={(e) => setInputUsuario(e.target.value)} />
                        </div>

                        <div>
                            <label>Chave Pix</label>
                            <input type="text" value={inputPix}
                                onChange={(e) => setInputPix(e.target.value)} />
                        </div>

                        <div>
                            <label>Senha</label>
                            <input type="text" value={inputSenha}
                                onChange={(e) => setInputSenha(e.target.value)} />
                        </div>

                        <div>
                            <label>Foto</label>
                            <input type="text" value={inputFoto}
                                onChange={(e) => setInputFoto(e.target.value)} />
                        </div>
                    </div>

                    <div className="btn-config" onClick={newUser}>
                        <h2>CRIAR</h2>
                    </div>

                    <div className="divisor"><img src={divisor} /></div>

                    <div className="cfg-title">
                        <h1>• ATUALIZAR SALDO</h1>
                    </div>

                    <div className="attTickets">
                        <select
                            id="seletorUsuarios"
                            onChange={(e) => setIdSelecionado(e.target.value)}
                            value={idSelecionado}
                        >
                            <option value="" disabled>Selecione</option>
                            {IDs.map((user) => (
                                <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                        </select>

                        <div className="tickets-atualization">
                            <div>
                                <div onClick={recuperarSaldoTickets}>
                                    <label>Atualizar</label>
                                </div>
                                <div>
                                    <input type="number" value={newSaldoTickets}
                                        onChange={(e) => setNewSaldoTickets(e.target.value)} />
                                </div>
                                <div className="control-icons">
                                    <span onClick={incrementValue}><CgMathPlus /></span>
                                    <span onClick={decrementValue}><CgMathMinus /></span>
                                </div>
                            </div>
                        </div>

                        <div className="full-width">
                            <div className="btn-config" onClick={novoSaldoTickets}>
                                <h2>ATUALIZAR</h2>
                            </div>
                        </div>
                    </div>


                    <div className="divisor"><img src={divisor} /></div>

                    <div className="cfg-title">
                        <h1>• BLOQUEIO DE RODADAS</h1>
                    </div>

                    <div className="lock-round">
                        <span className="lock-true" onClick={bloquearRodada}><FaLock /></span>
                        <span className="lock-false" onClick={desbloquearRodada}><FaLockOpen /></span>
                    </div>

                    <p className={lockedRound ? 'p-lock' : 'p-unlock'}>Rodada {lockedRound ? 'Bloqueada' : 'Desloqueada'}</p>

                    <div className="divisor"><img src={divisor} /></div>

                    <div className="cfg-title">
                        <h1>• FECHAR RODADA</h1>
                    </div>

                    <div className="full-width">
                        <div className="btn-config" onClick={fecharMudarRodada}>
                            <h2>MUDAR</h2>
                        </div>
                    </div>

                    <div className="divisor"><img src={divisor} /></div>

                    <div className="cfg-title">
                        <h1>• ZERAR PRÊMIO</h1>
                    </div>

                    <div className="full-width">
                        <div className="btn-config" onClick={zeraPremioRodada}>
                            <h2>ZERAR</h2>
                        </div>
                    </div>

                    <div className="divisor"><img src={divisor} /></div>

                    <div className="cfg-title">
                        <h1>• DEFINIR PARTIDAS</h1>
                    </div>

                    {Object.keys(dadosJogos).map((nomeJogo) => (
                        <div key={nomeJogo} className="match-data">
                            <p>{nomeJogo}</p>
                            <div className="versus">
                                <div>
                                    <label>Casa</label>
                                    <input
                                        type="number"
                                        className="team-number"
                                        value={dadosJogos[nomeJogo].casa}
                                        onChange={(e) => handleChange(nomeJogo, 'casa', e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Fora</label>
                                    <input
                                        type="number"
                                        className="team-number"
                                        value={dadosJogos[nomeJogo].visitante}
                                        onChange={(e) => handleChange(nomeJogo, 'visitante', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="fwth">
                                <label>Local</label>
                                <input
                                    type="text"
                                    className="match-gps"
                                    value={dadosJogos[nomeJogo].gps}
                                    onChange={(e) => handleChange(nomeJogo, 'gps', e.target.value)}
                                />
                            </div>
                        </div>
                    ))}


                    <div className="full-width">
                        <div className="btn-config" onClick={alterarPartidas}>
                            <h2>DEFINIR</h2>
                        </div>
                    </div>

                </div>

            </section >
        )
    };
};

export default AdmConfig;