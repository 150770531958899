import React, { useEffect, useState } from 'react';
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const NavbarAlt = () => {
    const [nameUser, setNameUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const recJson = localStorage.getItem('ID_TOKEN');
    const desJson = JSON.parse(recJson);
    const vrf = desJson.userId;

    const fetchData = async () => {
        const db = getFirestore(app);
        const userDocument = doc(db, 'user_data', vrf);

        try {
            const docSnapshot = await getDoc(userDocument);
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setNameUser(userData.name);
            } else {
                console.log('Erro!');
            }
        } catch (error) {
            console.error('Error!');
        } finally {
            // Atualizar o estado de carregamento após a conclusão da requisição
            setLoading(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const backToHome = () => {
        navigate('/home');
    }

    if (loading) {

        return (
            <header className="navbar">
                <FaUser size={17} color="#FFF" />
                <h3 className="logged-user">{nameUser ? nameUser : "..."}</h3>
                <div className="back-ico">
                    <IoChevronBackCircleSharp onClick={backToHome} size={30} color="#FFF" />
                </div>
            </header>
        )
    }
}

export default NavbarAlt;