import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/global.css';
import './css/homepage.css';
import './css/login.css';
import './css/bet.css';
import './css/userresults.css';
import './css/config.css';
import './css/rankings.css';
import './css/buytickets.css';
import './css/pixgenerator.css';
import './css/newaccount.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
