import React, { useEffect, useState } from 'react';
import app_logo from '../img/app_logo.png';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyCGY-IL0UqiNbyPS6twKaWgoNfdzJRP2GI",
    authDomain: "palpiteiroapp.firebaseapp.com",
    projectId: "palpiteiroapp",
    storageBucket: "palpiteiroapp.appspot.com",
    messagingSenderId: "497867147689",
    appId: "1:497867147689:web:472623985374257d2cdcb8",
    measurementId: "G-XBL8PE791X"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const Login = () => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const loginSucess = () => toast.success('Login realizado com sucesso!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold', // Defina o tamanho da fonte desejado
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const loginError = () => toast.error('Credenciais Inválidas!', {
        style: {
            fontSize: '35px',
            fontWeight: 'bold', // Defina o tamanho da fonte desejado
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        const authVfr = () => {
            const unAuthTokenData = {
                auth: 'unauth',
                userId: '0000000',
            };
            localStorage.setItem('ID_TOKEN', JSON.stringify(unAuthTokenData));
        };

        // Chame a função authVfr para executá-la ao carregar a página
        authVfr();
    }, []);

    const logado = () => {
        loginSucess();
        setTimeout(() => {
            navigate('/home');
        }, 2500);
    };

    const makeNewAccount = () => {
        navigate('/criarconta');
    };

    const handleLogin = async () => {
        try {
            const usersLoginsCollection = collection(db, 'users_Logins');

            const q = query(usersLoginsCollection, where('password', '==', password));

            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length > 0) {
                const userId = querySnapshot.docs[0].id;
                logado();
                const authTokenData = {
                    auth: 'auth',
                    userId: userId,
                };
                localStorage.setItem('ID_TOKEN', JSON.stringify(authTokenData));
            } else {
                console.log('Senha incorreta ou usuário não encontrado!');
                loginError();
            }
        } catch (error) {
            console.error('Erro ao tentar fazer login:');
            loginError();
        }
    };

    return (
        <section>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="login-logo">
                <img src={app_logo} />
            </div>

            <div className="login-container">
                <div className="credentials-screen">
                    <p>Insira suas credenciais:</p>
                    <input type="password" placeholder="Digite sua senha" value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button onClick={handleLogin}>Login</button>
                    <h5 onClick={makeNewAccount}>Criar conta</h5>
                    <h4><a href='https://api.whatsapp.com/send?phone=5577988190015&text=%5BPalpiteiro%20App%5D%0A%0AOl%C3%A1,%20preciso%20de%20suporte%20r%C3%A1pido!' target='_blank'>Suporte 📞</a></h4>
                </div>

            </div>
        </section>
    )
}

export default Login;